import Md from "../Md"

export type ErrorLabelProps = {
  error?: string,
  className?: string
  labelClassName?: string
}
export default function ErrorLabel({
  error,
  className = "?",
  labelClassName = "?",
  ...props
}: ErrorLabelProps) {
  return error ? <div className={labelClassName.replace('?', "label")}>
    <em
      className={className.replace('?', 'text-error')}
    ><Md>{error}</Md></em>
  </div> : null;
}