import clsx from "clsx"
import type { HTMLInputTypeAttribute } from "react";
import React, { useId, useState } from "react"
import type { InputProps, TextAreaProps } from "react-html-props"
import Md from "../Md"
import ErrorLabel from "./ErrorLabel"
import { useGetActionDataError, useGetActionDataErrors } from "./useGetActionDataError"
import cn from "~/helpers/styles";

type BaseInputFieldProps = {
  layout?: "inline" | "block" | "inline-reverse" | "inline-block"
  addOff?: boolean | never
  label?: string | JSX.Element
  labelId?: string
  name: string,
  id?: string,
  // type?: React.InputHTMLAttributes<HTMLInputElement>['type']
  inputVariant?: string
  useActionDataErrors?: boolean
  className?: string
  wrapperClassName?: string
  helperText?: string | JSX.Element
  helperClassName?: string
  error?: string
  parentClassName?: string
  labelClassName?: string
  element?: React.ElementType
  addJoin?: boolean
  startAdornment?: React.ReactNode
  afterLabelAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
} & Omit<InputProps, "ref" | "type">

type T1 = BaseInputFieldProps & {
  type?: "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week"
  returnOff?: never
}

type T2 = BaseInputFieldProps & {
  type?: "checkbox"
  returnOff?: boolean
}


export type InputFieldProps = T1 | T2

const InputField = React.forwardRef<any, InputFieldProps>(({
  name,
  id = name,
  label,
  labelId,
  addOff,
  layout = "block",
  type = 'text',
  // useActionDataErrors = true,
  inputVariant,
  wrapperClassName = '?',
  labelClassName = '?',
  className = '?',
  helperText,
  helperClassName = '?',
  error,
  parentClassName,
  element: Element = "input",
  addJoin = true,
  startAdornment,
  afterLabelAdornment,
  endAdornment,
  returnOff = false,
  onChangeCapture = () => { },
  defaultChecked,
  ...inputProps
}, ref) => {


  const uuid = useId();
  const errorStd = useGetActionDataError(name);
  const [checkboxValue, setCheckboxValue] = useState<"on" | "off">(defaultChecked ? 'on' : 'off');
  error ??= errorStd

  const inline = layout.startsWith('inline');
  const hasLabel = label !== undefined;
  const isBlock = layout.startsWith('block');
  const inlineRev = layout === 'inline-reverse';
  const inlineBlock = layout === 'inline-block';

  wrapperClassName = wrapperClassName.replace('?',
    clsx('w-full', hasLabel && addJoin && "join", inlineRev && 'flex-row-reverse justify-end', hasLabel && isBlock && "join-vertical")
  );
  labelClassName = labelClassName.replace('?',
    clsx('label cursor-pointer',
      inlineRev && 'flex-row-reverse justify-end',
      inlineBlock && "w-full !p-0",
      inlineBlock && addJoin && "[&_.label-text]:join-item [&_.label-text]:px-2",
      inline && "[&_.label-text]:flex-grow",
      !['checkbox', 'radio'].includes(type) && "bg-base-300 ")
  );

  const innerOnChangeCapture: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (type === 'checkbox') {
      setCheckboxValue(e.target.checked ? 'on' : 'off');
    }
    onChangeCapture(e);
  }

  className = className.replace('?', clsx('w-full input input-bordered', addJoin && 'join-item'));
  // const { errors } = (actionData ? actionData : undefined) ?? { errors: undefined };

  const innerInput = (<>
    {addOff && !returnOff && type === 'checkbox' && <input type="hidden" name={name} value={"off"} />}
    {returnOff && type === 'checkbox' && <input type="hidden" name={name} value={checkboxValue} />}

    <Element
      id={id}
      name={returnOff ? undefined : name}
      type={type}
      aria-describedby={`${name}-error`}
      aria-invalid={error ? true : undefined}
      className={`${className} ${inputVariant ?? ''} ${error ? 'input-error' : ''}`.trim()}
      spellCheck={false}
      onChangeCapture={innerOnChangeCapture}
      defaultChecked={defaultChecked}
      {...inputProps}
      ref={ref}
    />
  </>)

  const component = <>
    <div className={wrapperClassName}>
      {startAdornment}
      {label && <label
        className={labelClassName}
        htmlFor={id}
        id={labelId}
      >
        <span
          className="label-text"
        >{label}</span>
        {afterLabelAdornment}
        {inline ? innerInput : undefined}
      </label>}
      {inline ? undefined : innerInput}
      {endAdornment}
      <ErrorLabel error={error} />
      {!error && helperText ?
        (typeof helperText === 'string' ?
          <label className={cn("?: label", helperClassName, addJoin && "join-item")}>
            <span
              className="label-text text-info"
            ><Md>{helperText}</Md></span>
          </label> : helperText) : null}
    </div>
  </>

  if (parentClassName === undefined) {
    return component;
  }

  return <div className={parentClassName}>{component}</div>
});
InputField.displayName = 'InputField';
export default InputField