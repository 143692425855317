import { useActionData } from "@remix-run/react";

export function useGetActionDataErrors() {
  const actionDatas = useActionData<Record<string, any>>()

  let errors: Record<string, string> = {};

  if (actionDatas && typeof actionDatas === 'object' && 'errors' in actionDatas) {
    errors = actionDatas.errors;
  }

  const hasError = (name: string) => (typeof errors[name] === 'string' && errors[name].trim().length > 0);
  const getError = (name: string) => (hasError(name) ? errors[name] : undefined);


  return {
    errors,
    hasError,
    getError,
  }
}

export function useGetActionDataError(name: string) {
  const { errors } = useGetActionDataErrors();
  return typeof errors[name] === 'string' ? errors[name] : undefined;
}
